import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'

import TextInput from '~/components/Input/Text.tsx'
import Modal from '~/components/Modal'
import User from '~/types/entities/user.ts'

interface ProfileSwitcherProps {
  user: User
  setShowProfileSwitcher: (show: boolean) => void
  setLocalUser: (userIndex?: number) => void
  isOpen: boolean
}

const ProfileSwitcher: React.FC<ProfileSwitcherProps> = ({
  user,
  setShowProfileSwitcher,
  setLocalUser,
  isOpen,
}) => {
  const [showPinDialog, setShowPinDialog] = useState<boolean>(false)
  const [pinValue, setPinValue] = useState<string>('')

  const handleSwitchUser = async (userIndex?: number) => {
    setShowProfileSwitcher(false)
    setLocalUser(userIndex)
  }

  const handleSwitchGuardian = async (pin?: string) => {
    if (pin !== user.guardianDetails?.accessPin) {
      return
    }
    setShowProfileSwitcher(false)
    setLocalUser(undefined)
  }

  const handleShowDialog = () => {
    setPinValue('')
    setShowPinDialog(true)
  }

  if (!isOpen) return null
  return (
    <>
      <div className="absolute top-0 left-0 h-screen w-screen">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          role="button"
          tabIndex={-1}
          className="bg-gray-100 bg-opacity-75 w-full h-full fixed top-0 left-0 z-40"
          onClick={() => setShowProfileSwitcher(false)}
        />
        <div className="relative mx-auto w-2/3 mt-[200px] bg-gray-600 rounded text-white text-center p-10 shadow-lg z-50">
          <span className="text-4xl font-semibold">Users</span>
          <XMarkIcon
            className="top-5 right-5 h-8 w-8 absolute hover:scale-110 transition"
            onClick={() => setShowProfileSwitcher(false)}
          />
          <div className="grid grid-cols-3 gap-y-5 mx-auto mt-8">
            <div
              role="button"
              className="hover:-translate-y-2 transition"
              onClick={() => handleShowDialog()}>
              <img
                className="h-24 w-24 mb-3 rounded mx-auto"
                src={
                  user.avatar || 'https://api.dicebear.com/7.x/fun-emoji/svg?seed=u222'
                }
                alt="avatar"
              />
              <span className="text-2xl">{user.name}</span>
              <span className="block">Guardian</span>
            </div>
            {user.guardianDetails?.students?.length
              ? user.guardianDetails.students.map((student, index) => (
                  <div
                    role="button"
                    className="hover:-translate-y-2 transition"
                    onClick={() => handleSwitchUser(index)}
                    key={student.id}>
                    <img
                      className="h-24 w-24 mx-auto mb-3 rounded"
                      src={student.avatar}
                      alt="avatar"
                    />
                    <span className="text-2xl">{student.name}</span>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <Modal
        reverseButtons
        isOpen={showPinDialog}
        setIsOpen={setShowPinDialog}
        onSubmit={() => handleSwitchGuardian(pinValue)}
        submitText="Switch profile"
        closeText="Cancel">
        <p className="text-2xl font-medium text-center">Please enter your PIN</p>
        <TextInput
          placeholder="Guardian PIN"
          type="password"
          name="pin"
          className="mt-5"
          value={pinValue}
          onChange={(e) => setPinValue(e.target.value)}
        />
      </Modal>
    </>
  )
}

export default ProfileSwitcher
